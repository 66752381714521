import React, { useEffect } from 'react';
import classes from "./PressureGraphPanel.module.css";

import { ReactComponent as PressureGraphSvg } from '../../../../../assets/mocks/pressure-graph.svg';


const PressureGraphPanel = ({className, onClick, selectedPressureArea}) => {

    const updateSvgElement = (area) => {
        document.getElementById("pressure_graph_area").textContent = area;
    }

    useEffect(() => {
        if(selectedPressureArea) {
            updateSvgElement(selectedPressureArea);
        }
    });

    return (
        <div className={`${classes.container} ${className}`} onClick={onClick}>
            <PressureGraphSvg className={classes.svg} />
      </div>
  
    )

}

export default PressureGraphPanel;