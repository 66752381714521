import React, { useEffect } from 'react';
import classes from "./TemperatureGraphPanel.module.css";

import { ReactComponent as TemperatureGraphSvg } from '../../../../../assets/mocks/temperature-graph.svg';

const TemperatureGraphPanel = ({className, onClick, selectedTemperatureArea}) => {

    const updateSvgElement = (area) => {
        document.getElementById("temp_graph_area").textContent = area;
    }

    useEffect(() => {
        if(selectedTemperatureArea) {
            updateSvgElement(selectedTemperatureArea);
        }
    });

    
    return (
        <div className={`${classes.container} ${className}`} onClick={onClick}>
            <TemperatureGraphSvg className={classes.svg} />
      </div>
  
    )
}

export default TemperatureGraphPanel;